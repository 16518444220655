import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Products from "./components/Products/Products";
import aos from "aos";
import Scroll from "./common/Scroll";

function App() {
  React.useEffect(() => {
    aos.init({
      duration: 800,
      once: true,
    });
  });
  return (
    <BrowserRouter>
      <Scroll />
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/products" component={Products} />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
