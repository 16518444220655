import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  about: {
    width: "100%",
    minHeight: "400px",

    background: "white",
    display: "flex",
    margin: "4rem 0",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: " 0",
    },
  },
  left: {
    width: "65%",

    padding: "2rem 0 2rem 2rem",
    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "0",
      width: "100%",
      padding: "2rem 0",
    },
  },

  heading: {
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  img: {
    width: "35%",
    maxHeight: "500px",

    // width: "100%",
    // // borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "100%",
    // [theme.breakpoints.down("md")]: {
    //   borderRadius: "0",
    //   maxHeight: "500px",
    // },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRadius: "0",
    },
  },
  text: {
    fontSize: "1.2rem",
    marginTop: "1rem",
    paddingLeft: "4rem",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      paddingLeft: "0rem",
      margin: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
}));

export default function About() {
  const classes = useStyles();
  return (
    <Paper data-aos="fade-in-up" elevation={3} className={classes.about}>
      <div className={classes.left}>
        <h1 className={classes.heading}>Delivering Innovative Solutions</h1>
        <p className={classes.text}>
          Innovation forms the basis of all the endeavors at Q-Automation. We
          strive to nurture new technologies, products, solutions and services
          with an aim to improve the lives of people and the communities they
          live in. Leveraging over a decade of experience, we constantly
          reinvent to help you achieve better results, and create a more
          sustainable world for our current and future generations.
        </p>
      </div>

      <img
        src={require("../../img/innovation2.jpg")}
        className={classes.img}
        alt="innov"
      />
    </Paper>
  );
}
