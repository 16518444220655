import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import "./About.css";
const value = {
  breakpoints: "md",
};
const services = [
  {
    icon: require("../../img/facility.svg"),
    details:
      "Integrated manufacturing facility with a factory space of 4500 sq.ft",
  },
  {
    icon: require("../../img/worker.svg"),
    details: "Young and energetic  Electronic and Mechanical Design Engineers.",
  },
  {
    icon: require("../../img/talent.svg"),
    details:
      "Our  production engineers, Technicians and Assembly Line operators are highly skilled and trained",
  },
  {
    icon: require("../../img/network.svg"),
    details:
      " Excellent network with vendors, in both Electronics and Mechanical, with which we could keep our Capital investment lower.",
  },
];
const useStyles = makeStyles((theme) => ({
  facility: {
    width: "90%",
    margin: "4rem auto",
    padding: "2rem",
  },
  heading: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  content: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column",
    },
  },
  service: {
    minHeight: "150px",
    width: "45%",
    display: "flex",
    margin: "2rem 1rem",
    padding: "2rem",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down(value.breakpoints)]: {
      width: "99%",
      margin: "1rem auto",
      padding: "2rem  1rem",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "1rem auto",
    },
  },
  icon: {
    height: "100px",
  },
  details: {
    wordWrap: "break-word",
    margin: "0",
    width: "60%",
    lineHeight: "2rem",
    letterSpacing: "1px",
    fontSize: "1.1rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      marginLeft: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0 0 0",
      width: "95%",
    },
  },
}));
export default function About() {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.facility}>
      <h1 className={classes.heading}>Why Us?</h1>

      <div className={classes.content}>
        {services.map((item, i) => {
          return (
            <Paper
              data-aos="zoom-in-up"
              elevation={3}
              key={i}
              className={classes.service}
            >
              <div className={classes.icon}>
                <img className={classes.icon} src={item.icon} alt="icon" />
              </div>

              <p className={classes.details}>{item.details}</p>
            </Paper>
          );
        })}
      </div>
    </Paper>
  );
}
