import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import value from "../../common/const";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  products: {
    minHeight: "90vh",
  },
  heading: {
    margin: "14vh 0 2rem 0",
    fontSize: "1.5rem",
    fontWeight: "100",
    letterSpacing: "5px",
    textAlign: "center",
    [theme.breakpoints.down(value.breakpoints)]: {
      margin: "9vh 0 4vh 0",
      fontSize: "1.5rem",
    },
  },
  product: {
    display: "flex",
    padding: "3rem",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginBottom: "2rem",
    width: "95%",
    maxWidth: "1000px",
    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "1.5rem",
    },
  },
  div: {
    height: "300px",
    width: "200px",

    overflow: "hidden",
    [theme.breakpoints.down(value.breakpoints)]: {
      margin: "1rem 0",
      height: "250px",
      width: "150px",
    },
  },
  img: {
    height: "300px",
    width: "200",
    [theme.breakpoints.down(value.breakpoints)]: {
      height: "250px",
      width: "150",
    },
  },
  details: {
    display: "grid",
    placeItems: "center",
    fontSize: "1rem",
    marginLeft: "2rem",
  },
  name: {
    letterSpacing: "2px",
    fontSize: "1.5rem",
    fontWeight: "600",
  },
  description: {},
  divider: {
    display: "none",
  },
  enquire: {
    margin: "1rem",
  },
  ul: {
    listStyle: "circle",
  },
}));
export default function Products() {
  const classes = useStyles();
  const products = [
    {
      name: "Touch Free Sanitizer Dispenser",
      description: (
        <div>
          <p>
            Clean your hands and Keep away from virus. Make use of the new Touch
            free
            <br></br>
            Sanitizer Dispenser with :
          </p>
          <ul className={classes.ul}>
            <li>Easy Installation</li>
            <li>Easy Liquid Refilling</li>
            <li>Suitable for Liquid Sanitizer</li>
            <li>Adjustable flow </li>
            <li>See through container 1.8 Litre Capacity</li>
            <li>Troublefree working on sunlight also</li>
            <li>With Rechargeable battery(optional)</li>
          </ul>
        </div>
      ),
      url: require("../../img/sanitizer2.png"),
    },
  ];
  React.useEffect(() => {
    document.title = "Products";
  });
  return (
    <div className={classes.products}>
      <h1 className={classes.heading}>Our Products</h1>

      {products.map((item, i) => {
        return (
          <Paper elevation={3} className={classes.product} key={i}>
            <div className={classes.div}>
              <img
                data-aos="zoom-in"
                className={classes.img}
                src={item.url}
                alt={item.name}
              />
            </div>
            <div data-aos="zoom-in" className={classes.details}>
              <h3 className={classes.name}>{item.name}</h3>
              {item.description}
              <Link className={classes.enquire} to="/contact">
                <Button variant="contained" color="primary">
                  Enquire
                </Button>
              </Link>
              <hr className={classes.divider} />
            </div>
          </Paper>
        );
      })}
    </div>
  );
}
