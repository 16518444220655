import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import "./footer.css";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  icon: {
    height: "3rem",
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-left">
          <h4 className="footer-heading">Reach us</h4>
          <div className="footer-content">
            <p>9/36,Samiyar Medai</p>
            <p>Near Government Yarn Unit</p>
            <p>Irugur</p>
            <p>Coimbatore-641103</p>
            <p>call: +91 9500-87180</p>
          </div>
        </div>
        <div className="footer-middle">
          <h4 className="footer-heading">Explore</h4>
          <div className="footer-content">
            <ul>
              <Link to="/products">
                <li>Products</li>
              </Link>
              <Link to="/about">
                <li>About us</li>
              </Link>
              <Link to="/contact">
                <li>Let's Talk</li>
              </Link>
              <Link to="/">
                <li>Homepage</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="footer-right">
          <h4 className="footer-heading">Follow us</h4>
          <div className="footer-content">
            <Link to="/">
              <FacebookIcon fontSize="large" className={classes.icon} />
            </Link>
            <Link to="/">
              <InstagramIcon fontSize="large" className={classes.icon} />
            </Link>
            <Link to="https://api.whatsapp.com/send?phone=+919500087180">
              <WhatsAppIcon fontSize="large" className={classes.icon} />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          &copy; Q Automation,All rights Reserved {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}
