import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import value from "../../common/const";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  intro: {
    padding: "1rem",
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "6rem",

    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column-reverse",
      marginTop: "2rem",
      textAlign: "center",
    },
  },
  textContainer: {
    maxWidth: "50%",
    padding: "0 3rem ",
    margin: "auto",
    [theme.breakpoints.down(value.breakpoints)]: {
      maxWidth: "100%",
      padding: "0",
    },
  },
  textHeading: {
    marginTop: "0",
    fontWeight: "600",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "1.4rem",
    },
  },
  textPara: {
    fontSize: "1.3rem",
    lineHeight: "2.2rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "1rem",
      lineHeight: "1.7rem",
    },
  },
  imgContainer: {
    maxWidth: "50%",
    [theme.breakpoints.down(value.breakpoints)]: {
      display: "none",
    },
    margin: "auto",
  },
  img: {
    height: "400px",
    margin: "auto",
  },

  a: {
    display: " block",

    marginTop: "2rem",

    [theme.breakpoints.down(value.breakpoints)]: {
      margin: "2rem auto",
      textAlign: "center",
    },
  },
  btn: {
    // height: "50px",
    width: "150px",
    fontSize: "1rem",
    // padding: "1rem 1.5rem",
    textTransform: "none",
    borderRadius: "50px",
    "&:hover": {
      color: "#111",
      background: "#e0e0e0",
      boxShadow: "1px 1px 5px #111",
    },
  },
}));

export default function Intro() {
  const classes = useStyles();
  return (
    <div className={classes.intro}>
      <div data-aos="fade-right" className={classes.textContainer}>
        <h2 className={classes.textHeading}>Who are We</h2>
        <div className={classes.textPara}>
          <p>
            Q automation formed in 2011 with aim to develop control systems and
            mechatronics for Industrial automation
          </p>
          <p>
            With the rich experience of the team in Mechanical, Electronics ,
            Firmware and Software , we could develop products , based on the
            inputs from our customers, for Textile, Food processing and for
            Medical industry.
          </p>
        </div>
        <Link className={classes.a} to="/about">
          <Button className={classes.btn} variant="contained" color="primary">
            Know More
          </Button>
        </Link>
      </div>
      <div data-aos="fade-left" className={classes.imgContainer}>
        <img
          className={classes.img}
          src={require("../../img/office.jpg")}
          alt="company"
        />
      </div>
    </div>
  );
}
