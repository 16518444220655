import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import value from "../../common/const";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiPhoneInput from "material-ui-phone-number";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    marginTop: "-10%",
    [theme.breakpoints.down(value.breakpoints)]: {
      padding: "1rem",
      marginTop: "0",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bg: {
    height: "40vh",
    backgroundImage: `url(${require("../../img/contact2.jpg")})`,
    backgroundPosition: "center" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "cover",
    background: "#111",
    [theme.breakpoints.down(value.breakpoints)]: {},
  },
  heading: {
    margin: "0",
  },
  content: {
    height: "50%",
    display: "flex",
    marginBottom: "8rem",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-evenly",
    },
  },
  para: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  text: {
    [theme.breakpoints.down(value.breakpoints)]: {
      textAlign: "center",
      padding: "2rem",
    },
  },
  input: {
    margin: "1rem",
    minWidth: "300px",
  },
  textarea: {
    margin: "1rem",
    minWidth: "300px",
  },
  btn: {
    background: "#111",
    color: "#fff",
    "&:hover": {
      color: "#111",
    },
  },
}));
export default function Contact() {
  const classes = useStyles();

  React.useEffect(() => {
    document.title = "Contact us";
  });
  return (
    <>
      <div className={classes.bg}></div>
      <div className={classes.content}>
        <div data-aos="fade-right" className={classes.text}>
          <h1 className={classes.heading}>Let’s see if we’re a fit!</h1>
          <p className={classes.para}>
            We’d love to answer your questions about Q Automation<br></br> and
            how we power impactful, data-driven influencer<br></br> marketing
            for others.
          </p>
        </div>
        <Paper data-aos="zoom-in-up" className={classes.paper} elevation={3}>
          <form
            className={classes.form}
            style={{ background: "white" }}
            action="https://formspree.io/mdowybkr"
            method="POST"
          >
            <TextField
              className={classes.input}
              label="Name"
              variant="outlined"
              name="name"
              required
            />
            <TextField
              className={classes.input}
              label="Email id"
              variant="outlined"
              name="_replyto"
              required
            />
            <MuiPhoneInput
              className={classes.input}
              label="Mobile No"
              variant="outlined"
              defaultCountry="in"
              name="Mobile No"
            />
            <TextField
              placeholder="Type your messages here"
              label="Message"
              name="message"
              className={classes.input}
              multiline
              rows={6}
              rowsMax={Infinity}
              variant="outlined"
              required
            />
            <Button type="submit" variant="contained" className={classes.btn}>
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </>
  );
}
