import React from "react";
import { makeStyles, Button, Paper } from "@material-ui/core";
import value from "../../common/const";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  products: {
    textAlign: "center",
    width: "100%",
    marginBottom: "3rem",
  },
  heading: {
    fontSize: "1.8rem",
    fontWeight: "600",
    marginBottom: "3rem",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "3rem",
  },
  item: {
    padding: "1rem",
    maxWidth: "95vw",
  },
  btn: {
    width: "150px",
    fontSize: "1rem",
    textTransform: "none",
    borderRadius: "50px",
    "&:hover": {
      color: "#111",
      background: "#e0e0e0",
      boxShadow: "1px 1px 5px #111",
    },
  },
  name: {
    margin: "2rem auto 0 auto",
    borderTop: "1px solid #b3b6ba",
    padding: "1rem",
    fontSize: "1.1rem",
    fontWeight: "600",
  },
  img: {
    height: "400px",
    width: "300px",
    margin: "auto",
    [theme.breakpoints.down(value.breakpoints)]: {
      height: "300px",
      width: "200px",
    },
  },
}));
export default function Products() {
  const classes = useStyles();
  return (
    <div className={classes.products}>
      <h1 data-aos="fade-in-up" className={classes.heading}>
        Our Products
      </h1>
      <div data-aos="flip-right" className={classes.container}>
        <Paper elevation={3} className={classes.item}>
          <img
            className={classes.img}
            src={require("../../img/sanitizer2.png")}
            alt="item"
          />
          <p className={classes.name}>Touch Free Hand Sanitizer Dispenser</p>
        </Paper>
      </div>
      <Link className={classes.a} to="/products">
        <Button className={classes.btn} variant="contained" color="primary">
          View All
        </Button>
      </Link>
    </div>
  );
}
