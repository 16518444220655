import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  imgContainer: {},
  img: {
    maxHeight: "100vh",
    minHeight: "50vh",

    width: "100%",
    flexShrink: "0",
  },
  textHeading: {
    fontSize: "3.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      marginBottom: "0",
    },
  },
  text: {
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginTop: "1rem",
    },
  },
  content: {
    textShadow: "1px 1px 5px #111",
    [theme.breakpoints.down("xs")]: {},
  },
}));

export default function Carousel() {
  const classes = useStyles();
  return (
    <div data-aos="fade-in">
      <div
        id="carouselExampleCaptions"
        className="carousel slide carousel-fade"
        data-ride="carousel"
        data-interval="3000"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleCaptions"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div data-aos="fade-in" className="carousel-inner">
          {[
            {
              img: require("../../img/carousel.png"),
              title: "Q-Automation",
              text: "Innovation Unlimited",
            },

            {
              img: require("../../img/carousel3.jpg"),
              title: "Q-Automation",
              text: "Innovation Unlimited",
            },
            {
              img: require("../../img/carousel1.jpg"),
              title: "Q-Automation",
              text: "Innovation Unlimited",
            },
          ].map((item, i) => {
            return (
              <div
                key={i}
                className={"carousel-item" + (i === 0 ? " active" : "")}
              >
                <img
                  src={item.img}
                  className={classes.img + " d-block w-100"}
                  alt="..."
                />
                <div
                  className={
                    " carousel-caption  d-md-block strokeme " + classes.content
                  }
                >
                  <h1 className={classes.textHeading}>{item.title}</h1>
                  <h3 className={classes.text}>{item.text}</h3>
                </div>
              </div>
            );
          })}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
