import React from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import InfoIcon from "@material-ui/icons/Info";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button } from "@material-ui/core";
import styles from "./Style";
const breakpoints = "sm";

export default function PersistentDrawerLeft() {
  const classes = styles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.down(breakpoints));
  const [value, setValue] = React.useState(0);
  const [path, setPath] = React.useState(window.location.pathname);
  const [bg, setBg] = React.useState(
    window.pageYOffset < 50 ? "transparent" : "secondary"
  );
  const [elevation, setElevation] = React.useState(
    window.pageYOffset < 50 ? 0 : 3
  );
  const navitems = [
    {
      name: "Home",
      path: "/",
      icon: <HomeIcon />,
    },
    {
      name: "Products",
      path: "/products",
      icon: <AddShoppingCartIcon />,
    },
    {
      name: "About us",
      path: "/about",
      icon: <InfoIcon />,
    },
    {
      name: "Contact us",
      path: "/contact",
      icon: <ContactSupportIcon />,
    },
  ];

  const scrollHandler = () => {
    if (window.pageYOffset < 50) {
      setBg("transparent");
      setElevation(0);
    } else {
      setBg("secondary");
      setElevation(3);
    }
  };

  React.useEffect(() => {
    console.log("ame");

    if (path === "/" && value !== 0) {
      setValue(0);
    } else if (path === "/products" && value !== 1) setValue(1);
    else if (path === "/about" && value !== 2) setValue(2);
    else if (path === "/contact" && value !== 3) setValue(3);
    window.addEventListener("scroll", scrollHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, path]);

  const handleTab = (value) => {
    setValue(value);
    setPath(navitems[value].path);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color={bg}
        elevation={elevation}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {matches ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Link className={classes.right} to="/"></Link>
            </>
          ) : (
            <>
              <Link onClick={() => setPath("/")} to="/"></Link>
              <Tabs
                classes={{
                  indicator: classes.indicator,
                }}
                value={value}
                className={classes.tabs}
                onChange={(e, v) => handleTab(v)}
                aria-label="simple tabs example"
              >
                {navitems.map((item, i) => {
                  return (
                    <Tab
                      className={
                        (value === i ? classes.active : "none") +
                        " " +
                        classes.tab
                      }
                      key={i}
                      label={item.name}
                      component={Link}
                      to={item.path}
                    />
                  );
                })}
              </Tabs>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navitems.map((item, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  handleDrawerClose();
                }}
              >
                <Link to={item.path}>
                  <ListItem>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                  </ListItem>
                </Link>
              </Button>
            );
          })}
        </List>
      </Drawer>
    </div>
  );
}
