import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import logo from "../../img/logo.png";
import bg from "../../img/company2.jpg";
import Outcome from "./Outcome";
import Facility from "./Facility";

import "./About.css";
const value = {
  breakpoints: "md",
};
const useStyles = makeStyles((theme) => ({
  about: {},
  bg: {
    marginTop: "10vh",
    height: "30vh",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "cover",
    background: "#111",
  },
  content: {
    width: "100%",
    margin: "auto",
  },
  main: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column",
    },
  },

  name: {
    width: "50%",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(value.breakpoints)]: {
      width: "95%",
      margin: "auto",
      marginTop: "2rem",
      marginBottom: "0rem",
    },
  },

  details: {
    width: "40%",
    padding: "2rem",
    margin: "3rem 2rem",
    marginTop: "-8rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      width: "95%",
      margin: "2rem auto",
    },
  },
  mainHeading: {
    textAlign: "center",
    fontSize: "2rem",
  },
  mainContent: {
    wordWrap: "break-word",
    textIndent: "5rem",
    lineHeight: "2rem",
    letterSpacing: "1px",
    fontSize: "1.1rem",
  },
  logo: {
    height: "90px",
    [theme.breakpoints.down(value.breakpoints)]: {
      height: "65px",
    },
  },
  nameHeading: {
    fontSize: "2.8rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "1.8rem",
    },
  },
  logText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moto: {
    margin: "0 0 0 2rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "1.2rem",
    },
  },
  list: {
    padding: "2rem",
    listStyle: "square",
  },
  mission: {
    width: "95%",
    margin: "4rem auto",
    maxWidth: "1000px",
    padding: "2rem",
  },
}));
export default function About() {
  const classes = useStyles();
  React.useEffect(() => {
    document.title = "About us";
  });
  return (
    <div data-aos="fade-in" className={classes.about}>
      <div className={classes.bg}></div>
      <div className={classes.content}>
        <div className={classes.main}>
          <div data-aos="fade-right" className={classes.name}>
            <div className={classes.logText}>
              <img className={classes.logo} src={logo} alt="logo" />
              <h1 className={classes.nameHeading}>-Automation</h1>
            </div>
            <h3 className={classes.moto}>Innovation Unlimited</h3>
          </div>

          <Paper data-aos="fade-left" elevation={3} className={classes.details}>
            <h3 className={classes.mainHeading}>About us</h3>
            <p className={classes.mainContent}>
              Q automation formed in 2011 with aim to develop control systems
              and mechatronics for Industrial automation. With the rich
              experience of the team in Mechanical, Electronics , Firmware and
              Software , we could develop products , based on the inputs from
              our customers, for Textile, Food processing and for Medical
              industry.
            </p>
            <p className={classes.mainContent}>
              With 9 years of success in developing new products for our OEM and
              a high desire to grow further in other markets, Q Automation, is
              looking forward for the future with great expectations, as we
              approach with greater confidence. With an average employee age of
              26, the core of the company is young at heart and ambitious to
              grow. We expect extreme performance from ourselves and look to
              leap ahead on the strengths of our innovation.
            </p>
          </Paper>
        </div>

        <Outcome />

        <Facility />
      </div>
    </div>
  );
}
