import { createMuiTheme } from "@material-ui/core";
export default createMuiTheme({
  palette: {
    primary: {
      main: "#111",
    },
    secondary: {
      main: "#e0e0e0",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
