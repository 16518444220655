import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Home.css";
import Carousel from "./Carousel";
import Products from "./Products";
import About from "./About";
import Intro from "./Intro";

const useStyles = makeStyles((theme) => ({
  home: {
    width: "100%",
  },
}));
export default function Home() {
  const classes = useStyles();
  React.useEffect(() => {
    document.title = "Q Automation";
  });
  return (
    <div className={classes.home}>
      <Carousel />
      <Intro />
      <About />

      <Products />
    </div>
  );
}
