import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, useMediaQuery } from "@material-ui/core";

import "./About.css";
const value = {
  breakpoints: "md",
};
const useStyles = makeStyles((theme) => ({
  about: {},
  bg: {
    marginTop: "10vh",
    height: "30vh",
    backgroundImage: `url(${require("../../img/innovation.jpg")})`,
    backgroundPosition: "center" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "cover",
    background: "#111",
  },
  content: {
    width: "100%",
    margin: "auto",
  },
  main: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(value.breakpoints)]: {
      flexDirection: "column",
    },
  },

  name: {
    width: "50%",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(value.breakpoints)]: {
      width: "95%",
      margin: "auto",
      marginTop: "2rem",
      marginBottom: "0rem",
    },
  },

  details: {
    width: "40%",
    padding: "2rem",
    margin: "3rem 2rem",
    marginTop: "-8rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      width: "95%",
      margin: "2rem auto",
    },
  },
  mainHeading: {
    textAlign: "center",
    fontSize: "2rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      display: "none",
    },
  },
  mainContent: {
    wordWrap: "break-word",
    textIndent: "5rem",
    lineHeight: "2rem",
    letterSpacing: "1px",
    fontSize: "1.1rem",
    padding: "2rem 2rem 0 2rem",
  },
  logo: {
    height: "90px",
    margin: "1rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      height: "65px",
    },
  },
  nameHeading: {
    fontSize: "2.8rem",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "2rem",
    },
  },
  logText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moto: {
    margin: "0",
    [theme.breakpoints.down(value.breakpoints)]: {
      fontSize: "1.8rem",
    },
  },
  list: {
    padding: "2rem",
    listStyle: "square",
    lineHeight: "2rem",
    letterSpacing: "1px",
    fontSize: "1.1rem",
  },
}));
export default function About() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(value.breakpoints));

  return (
    <div className={classes.about}>
      <div className={classes.bg}></div>
      <div className={classes.content}>
        {matches ? (
          <div className={classes.main}>
            <div data-aos="fade-right" className={classes.name}>
              <div className={classes.logText}>
                <img
                  className={classes.logo}
                  src={require("../../img/rocketsvg.svg")}
                  alt="logo"
                />
                <h1 className={classes.nameHeading}>Our Mission</h1>
              </div>
            </div>

            <Paper
              data-aos="fade-left"
              elevation={3}
              className={classes.details}
            >
              <h3 className={classes.mainHeading}>What we aim </h3>
              <p className={classes.mainContent}>
                We believe that in the field of automation, there are unlimited
                capabilities to bring in new innovations and thus our logo says
                “Innovations Unlimited".<br></br>Our Objectives are:
              </p>
              <ul className={classes.list}>
                <li>
                  By Contributing Innovative Products Helping Human to reduce
                  the Physical and Mental stress.
                </li>
                <li>Increasing Productivity by Automating Process</li>
              </ul>
            </Paper>
          </div>
        ) : (
          <div className={classes.main}>
            <Paper
              data-aos="fade-right"
              elevation={3}
              className={classes.details}
            >
              <h3 className={classes.mainHeading}>What we aim </h3>
              <p className={classes.mainContent}>
                We believe that in the field of automation, there are unlimited
                capabilities to bring in new innovations and thus our logo says
                “Innovations Unlimited".<br></br>Our Objectives are:
              </p>
              <ul className={classes.list}>
                <li>
                  By Contributing Innovative Products Helping Human to reduce
                  the Physical and Mental stress.
                </li>
                <li>Increasing Productivity by Automating Process</li>
              </ul>
            </Paper>
            <div data-aos="fade-left" className={classes.name}>
              <div className={classes.logText}>
                <img
                  className={classes.logo}
                  src={require("../../img/rocketsvg.svg")}
                  alt="logo"
                />
                <h1 className={classes.nameHeading}>Our Mission</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
